<template>
    <MainContainer>
        <div class="events">
            <h2>Veranstaltungen</h2>
            <b-row class="my-4">
                <b-col md="9">
                    <b-row>
                        <b-col cols="12">
                            <h5>Filter</h5>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="3">
                            <h6>Typen</h6>
                            <CheckboxSelect
                                :options="typesSelect"
                                v-model="filter.type"
                                :size="size"
                            />
                        </b-col>
                        <b-col cols="3">
                            <h6>Bereiche</h6>
                            <CheckboxSelect
                                :options="sectionsSelect"
                                v-model="filter.section"
                                :size="size"
                            />
                        </b-col>
                        <b-col cols="3">
                            <h6>Standorte</h6>
                            <CheckboxSelect
                                :options="locationsSelect"
                                v-model="filter.locations"
                                :size="size"
                            />
                        </b-col>
                        <b-col cols="3">
                            <h6>Tags</h6>
                            <CheckboxSelect
                                :options="tagsSelect"
                                v-model="filter.tags"
                                :size="size"
                            />
                        </b-col>
                    </b-row>
                    <b-row class="my-1">
                        <b-col>
                            <b-button :size="size" @click="applyFilter"
                                >Filter anwenden</b-button
                            >
                        </b-col>
                    </b-row>
                </b-col>
                <b-col md="3">
                    <h5>Funktionen</h5>
                    <b-button
                        variant="outline-primary"
                        size="sm"
                        :to="{ path: `/events/new` }"
                        >Neue Veranstaltung</b-button
                    >
                    <b-button
                        class="ml-1"
                        variant="outline-danger"
                        size="sm"
                        :disabled="!(selected.length > 0)"
                        @click="openDeleteModal({ multi: true })"
                        >Auswahl löschen</b-button
                    >
                </b-col>
            </b-row>
            <b-row>
                <b-col md="12" class="my-4">
                    <TableFilter v-model="filter.pattern" />
                </b-col>
            </b-row>
            <b-table
                :items="events"
                :fields="currentSelectedFields"
                :filter="filter.pattern"
                :busy="busy.data"
                :small="size == 'sm'"
                select-mode="multi"
                sort-by="lastname"
                ref="selectableTable"
                empty-text="Keine Daten vorhanden"
                empty-filtered-text="Für den gewählten Filter wurden keine Daten gefunden"
                foot-clone
                no-footer-sorting
                selectable
                bordered
                striped
                responsive
                show-empty
                @row-selected="onRowSelected"
            >
                <template #thead-top="{ columns }">
                    <b-tr>
                        <b-td>
                            <p class="small m-0">Gesamt: {{ events.length }}</p>
                            <p class="small m-0">
                                Gewählt:
                                <template v-if="selected.length > 0"
                                    >{{ selected.length }}
                                </template>
                                <template v-else>-</template>
                            </p>
                        </b-td>
                        <b-td :colspan="currentRecordFields.length"></b-td>
                        <b-td class="text-right"
                            ><b-dropdown
                                variant="outline-secondary"
                                text="Spaltenauswahl"
                                :size="size"
                            >
                                <b-dropdown-form>
                                    <b-form-checkbox-group
                                        v-model="selectedFields"
                                        :options="recordFields"
                                        value-field="key"
                                        text-field="label"
                                    ></b-form-checkbox-group>
                                </b-dropdown-form>
                            </b-dropdown>
                        </b-td>
                    </b-tr>
                </template>

                <template #table-busy>
                    <div class="text-center text-secondary my-2">
                        <b-spinner class="mr-1 align-middle" small></b-spinner>
                        <strong>Daten werden geladen...</strong>
                    </div>
                </template>

                <template #head(__selected)>
                    <input
                        type="checkbox"
                        v-model="selectAll"
                        :true-value="true"
                        :false-value="false"
                        @change="toggleAllSelectedRows"
                    />
                </template>

                <template #cell(__selected)="{ rowSelected }">
                    <input
                        type="checkbox"
                        :checked="rowSelected"
                        :true-value="true"
                        :false-value="false"
                        disabled
                    />
                </template>

                <template #cell(title)="data">
                    <b-link :to="{ path: `/events/${data.item.id}` }">{{
                        data.value
                    }}</b-link>
                </template>

                <template #cell(startdate)="data">
                    {{ formatDate(data.value) }}
                </template>

                <template #cell(enddate)="data">
                    {{ formatDate(data.value) }}
                </template>

                <template #cell(type)="data">
                    {{ typeName(data.value) }}
                </template>

                <template #cell(section)="data">
                    {{ sectionName(data.value) }}
                </template>

                <template #cell(locations)="data">
                    <a
                        v-for="location_id in data.value"
                        :key="location_id"
                        href="#"
                        class="d-block"
                    >
                        {{ locationName(location_id) }}
                    </a>
                </template>

                <template #cell(tags)="data">
                    <b-badge
                        v-for="tag_id in data.value"
                        :key="tag_id"
                        class="mx-1"
                    >
                        {{ tagName(tag_id) }}
                    </b-badge>
                </template>

                <template #cell(__actions)="data">
                    <b-button
                        variant="outline-info"
                        size="sm"
                        class="mx-1"
                        @click="openConfirmDuplicateModal(data.item.id)"
                        >Duplizieren</b-button
                    >
                    <b-button
                        variant="outline-primary"
                        size="sm"
                        class="mx-1"
                        :to="{ path: `/events/${data.item.id}` }"
                        >Bearbeiten</b-button
                    >
                    <b-button
                        variant="outline-danger"
                        size="sm"
                        class="mx-1"
                        @click="openDeleteModal({ record: data.item })"
                        >Löschen</b-button
                    >
                </template>
            </b-table>

            <ConfirmModal
                id="confirm-duplicate-modal"
                title="Formular duplizieren"
                :bisy="busy.mutation"
                @ok="duplicateEvent"
            >
                Die Veranstaltung mit dem Titel "{{ eventName(duplicateId) }}"
                wirklich duplizieren?
            </ConfirmModal>

            <DeleteModal
                :mode="modificationMode"
                :record="modificationRecord"
                :fields="currentRecordFields"
                :selected="selected"
                :busy="busy.mutation"
                @ok="deleteRecord"
            >
            </DeleteModal>
        </div>
    </MainContainer>
</template>

<script>
import ModelView from "@/mixins/ModelView/ModelView";
import Size from "@/mixins/Size/Size";
import DateFormat from "@/mixins/Date/Date";
import Filter from "@/mixins/Filter/Filter";

import MainContainer from "@/components/MainContainer/MainContainer";
import FormGroupBuilder from "@/components/FormGroupBuilder/FormGroupBuilder";
import TableExport from "@/components/TableExport/TableExport";
import TableFilter from "@/components/TableFilter/TableFilter";
import ConfirmModal from "@/components/ConfirmModal/ConfirmModal";
import DeleteModal from "@/components/DeleteModal/DeleteModal";
import CheckboxSelect from "@/components/CheckboxSelect/CheckboxSelect";
import { mapActions, mapGetters } from "vuex";

import "./Events.scss";

export default {
    name: "Events",
    components: {
        MainContainer,
        FormGroupBuilder,
        TableExport,
        TableFilter,
        ConfirmModal,
        DeleteModal,
        CheckboxSelect
    },
    mixins: [ModelView, Size, DateFormat, Filter],
    data() {
        return {
            duplicateId: null,
            filter: {
                pattern: null,
                type: [],
                section: []
            },
            filterIdentifier: "events",
            fields: [
                {
                    key: "__selected",
                    label: "",
                    class: "text-center",
                    static: true,
                    position: "prepend"
                },
                {
                    key: "id",
                    label: "ID",
                    sortable: true
                },
                {
                    key: "title",
                    label: "Titel",
                    sortable: true
                },
                {
                    key: "token",
                    label: "Veranstaltungsnummer",
                    sortable: true
                },
                {
                    key: "startdate",
                    label: "Beginn",
                    sortable: true
                },
                {
                    key: "starttime",
                    label: "Uhrzeit",
                    sortable: true
                },
                {
                    key: "enddate",
                    label: "Ende",
                    sortable: true
                },
                {
                    key: "endtime",
                    label: "Uhrzeit",
                    sortable: true
                },
                {
                    key: "type",
                    label: "Typ",
                    sortable: true
                },
                {
                    key: "section",
                    label: "Bereich",
                    sortable: true
                },
                {
                    key: "locations",
                    label: "Standorte"
                },
                {
                    key: "tags",
                    lagel: "Tags"
                },
                {
                    key: "__actions",
                    label: "Aktionen",
                    class: "text-right",
                    static: true,
                    position: "append"
                }
            ],
            selectedFields: [
                "id",
                "title",
                "token",
                "startdate",
                "starttime",
                "enddate",
                "endtime",
                "type",
                "section",
                "locations",
                "tags"
            ],
            actions: {
                delete: "events/deleteEvent",
                deleteMulti: "events/deleteMultipleEvents"
            }
        };
    },
    computed: {
        ...mapGetters({
            busy: "events/getBusy",
            events: "events/getEvents",
            eventName: "events/getEventName",
            typeName: "eventsTypes/getTypeName",
            typesSelect: "eventsTypes/getTypesForSelect",
            sectionName: "eventsSections/getSectionName",
            sectionsSelect: "eventsSections/getSectionsForSelect",
            locationName: "locations/getName",
            locationsSelect: "locations/getSelectData",
            tagName: "eventsTags/getTagName",
            tagsSelect: "eventsTags/getTagsForSelect"
        }),
        dataAvailable() {
            return this.events.length > 0;
        }
    },
    created() {
        this.fetch({ filter: this.filter });
        this.fetchTypes();
        this.fetchSections();
        this.fetchLocations();
        this.fetchTags();
    },
    methods: {
        ...mapActions({
            fetch: "events/fetchEvents",
            duplicate: "events/duplicateEvent",
            fetchTypes: "eventsTypes/fetchTypes",
            fetchSections: "eventsSections/fetchSections",
            fetchLocations: "locations/fetchData",
            fetchTags: "eventsTags/fetchTags"
        }),
        applyFilter() {
            this.fetch({ filter: this.filter });
        },
        openConfirmDuplicateModal(event_id) {
            this.duplicateId = event_id;
            this.$bvModal.show("confirm-duplicate-modal");
        },
        duplicateEvent() {
            this.duplicate(this.duplicateId);
        }
    }
};
</script>
